import '../stylesheets/application.scss'

import jQuery from 'jquery'

import 'bootstrap'
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import '../utils/rails-ujs'

import toastr from 'toastr'
import 'toastr/build/toastr.css'
// Bootstrapの競合対策 https://github.com/CodeSeven/toastr/issues/599
toastr.options.toastClass = 'toastr';

// homeのカルーセル
document.addEventListener('DOMContentLoaded', () => {
  jQuery('#pickup-jobs').slick({
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  })
})

// jobs/showの画像カルーセル
document.addEventListener('DOMContentLoaded', () => {
  jQuery('.slick').slick({
    autoplay: true,
    centerMode: true,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    variableWidth: true,
  })
})

// jobs/showのvoiceカルーセル
document.addEventListener('DOMContentLoaded', () => {
  $('.show-more').on('click', function (e) {
    e.preventDefault()
    $($(this).data('target')).removeClass('grad-item')
    $(this).hide()
  })
  $('#company-voice').slick()
  $('#user-voice').slick()
})


// LINEバナーをクリックしたら利用規約確認モーダルを表示
document.addEventListener('DOMContentLoaded', () => {
  const modal_template = `
    <div class="modal fade" id="line-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <p>フード人材バンクの<a href="/terms" target="_blank">利用規約</a>と<a href="https://docs.google.com/document/d/e/2PACX-1vRVeKM7rPsERCKlZNLz_UBKI1-nSsv0LhP36-LtSVxx8BmTKNN1IS3Tn4pwc1hnE7Ql7x__GEvFH1JX/pub" target="_blank">個人情報の取扱い方針</a>をご確認ください。</p>
            <a href="{{line_link}}" class="my-4 btn btn-success btn-lg line-link">上記に同意して登録する</a>
          </div>
        </div>
      </div>
    </div>
  `
  $("[href^='https://line.me/']").on('click', e => {
    e.preventDefault()
    const modal = modal_template.replace("{{line_link}}", e.currentTarget.href)
    $(modal).modal()
  })
})
